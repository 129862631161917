import { useState } from 'react';
import { forwardRef } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Paper } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CompetitorsIcon } from 'shared/icons/CompetitorsIcon';
import { MaxPriceIcon } from 'shared/icons/MaxPriceIcon';
import { MinPriceIcon } from 'shared/icons/MinPriceIcon';
import { AveragePriceIcon } from 'shared/icons/AveragePriceIcon';
import { DateTime } from 'luxon';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useReadHostServicesByMonitorRunIdQuery } from 'services/hostService';
import { ChartPrices } from './components/ChartPrices';
import { ChartSupplyDemand } from './components/ChartSupplyDemand';
import { SortOrder } from '../../../../shared/enums';
import { ReadHostServicesByMonitorRunIdParams } from '../../../../services/hostService/qhostService';
import FoundationIcon from '@mui/icons-material/Foundation';
import { array } from 'zod';
import { MonitorRunsResultDetailCard } from '../../../../shared/types';
import { ReadMonitorRunResultParams, useReadMonitorRunResultGroupedByMonitorIdQuery } from '../../../../services/monitorRunResult';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UpdateIcon from '@mui/icons-material/Update';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { useReadMonitorByIdQuery } from 'services/monitor';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import { useUpdateMonitorOwnPriceMutation } from 'services/monitor';
import { isNull } from 'util';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#EEEEEE',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary,
}));

type MonitorLaunchTaskProps = {
    monitorLaunchTask?: any,
    onTitleClick?: Function,
    monitorId?: number
}

export const MonitorLaunchTask = forwardRef((props: MonitorLaunchTaskProps, ref: any): JSX.Element => {
    const {
        monitorLaunchTask,
        onTitleClick,
        monitorId
    } = props;

    const [readHostServicesByMonitorRunIdParams, setReadHostServicesByMonitorRunIdParams] = useState<ReadHostServicesByMonitorRunIdParams>({
        monitorRunId: monitorLaunchTask?.monitorRunsResult?.id || -1,
        sorting: {
            orderField: '',
            sortOrder: SortOrder.Ascending
        }
    })
    const [skip, setSkip] = useState<boolean>(monitorLaunchTask === undefined);
    const [order, setOrder] = useState<SortOrder>(SortOrder.Ascending);
    const [expandWasOpened, setExpandWasOpened] = useState<Boolean>(false);
    const summaryRootComponent = useRef<null | HTMLDivElement>(null); 
    const [updateMonitorOwnPrice] = useUpdateMonitorOwnPriceMutation();
    const useReadHostServicesByMonitorRunIdQueryOptions = {
        skip: skip
    }

    const [chartData, setChartData] = useState<Object | undefined>();
    const [chartOptions, setChartOptions] = useState<Object>();

    const {
        data: readHostServicesByMonitorRunIdData,
        isLoading: readHostServicesByMonitorRunIdIsLoading,
        isFetching: readHostServicesByMonitorRunIdisFetching
    } = useReadHostServicesByMonitorRunIdQuery(
        readHostServicesByMonitorRunIdParams,
        useReadHostServicesByMonitorRunIdQueryOptions
        );

    const groupBy =  (xs: any, key: any) => {
        return xs.reduce((rv: any, x: any) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };


    useEffect(() => {
        setSkip(monitorLaunchTask === undefined);
        if (monitorLaunchTask !== undefined) {
            setReadHostServicesByMonitorRunIdParams(params => ({
                ...params,
                monitorRunId: monitorLaunchTask?.monitorRunsResult?.id
            }));
        }
        
    }, [monitorLaunchTask]);

    const [skipReadMonitorRunResultGroupedByMonitorId, setSkipReadMonitorRunResultGroupedByMonitorId] = useState<boolean>(true);
    const readMonitorRunResultByMonitorIdQueryParams: ReadMonitorRunResultParams = {
        monitorId: monitorLaunchTask?.monitor?.id,
    };
    const [chartSupplyData, setChartSupplyData] = useState<Object | undefined>();
    const [chartSupplyOptions, setChartSupplyOptions] = useState<Object | undefined>({
            scales: {
                y: {

                    ticks: {
                        color: "#000000",
                        precision: 0
                    }
                },
                x: {

                    type: "timeseries",
                    stacked: true,
                    ticks: {
                        color: "#000000"
                    },
                    time: {
                        displayFormats: {
                            day: 'MM/dd/yyyy',
                            hour: 'MM/dd/yyyy',
                            minute: 'MM/dd/yyyy',
                        }
                    },
                    adapters: {
                        date: {
                            zone: 'utc'
                        }
                    }
                }
        },
        plugins: {
            annotation: {
                annotations: {
                    todayLine: {
                        type: 'line',
                        borderColor: 'black',
                        borderWidth: 4,
                        mode: "vertical",
                        value: DateTime.now(),
                        label: {
                            backgroundColor: 'red',
                            content: 'Today',
                            display: true
                        },
                        scaleID: 'x',
                    },
                }
            }
        }
    });

    const [chartDemandData, setChartDemandData] = useState<Object | undefined>();
    const [chartDemandOptions, setChartDemandOptions] = useState<any>({
        scales: {
            y: {

                ticks: {
                    color: "#000000",
                    precision: 0,
                    beginAtZero: true
                }
            },
            x: {

                type: "timeseries",
                stacked: true,
                ticks: {
                    color: "#000000"
                },
                time: {
                    displayFormats: {
                        day: 'MM/dd/yyyy',
                        hour: 'MM/dd/yyyy',
                        minute: 'MM/dd/yyyy',
                    }
                },
                adapters: {
                    date: {
                        zone: 'utc'
                    }
                }
            }
        },
        plugins: {
            annotation: {
                annotations: {
                    todayLine: {
                        type: 'line',
                        borderColor: 'black',
                        borderWidth: 4,
                        mode: "vertical",
                        value: DateTime.now(),
                        label: {
                            backgroundColor: 'red',
                            content: 'Today',
                            display: true
                        },
                        scaleID: 'x',
                    },
                }
            }
        }
    });

    useEffect(() => {
        setSkipReadMonitorRunResultGroupedByMonitorId(monitorLaunchTask?.monitor?.id === undefined || monitorLaunchTask.monitor.id === -1);
    }, [monitorLaunchTask]);

    const readMonitorRunResultByMonitorIdQueryOptions = {
        skip: skipReadMonitorRunResultGroupedByMonitorId,
        refetchOnMountOrArgChange: true,
        pollingInterval: 5000,
    };

    const {
        data: readMonitorRunResultGroupedByMonitorIdData,
        isLoading: readMonitorRunResultGroupedByMonitorIdsLoading,
        isFetching: readMonitorRunResultGroupedByMonitorIdIsFetching,
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        readMonitorRunResultByMonitorIdQueryParams,
        readMonitorRunResultByMonitorIdQueryOptions
        );

    const compareLuxonDates = (a: any, b: any) => {
        return a.checkinUtc.toMillis() - b.checkinUtc.toMillis()
    }

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData === undefined) {
            return;
        }

        if (monitorLaunchTask === undefined) {
            return;
        }

        const transformedSupplyDemand = readMonitorRunResultGroupedByMonitorIdData.list
            .map((resultItem: any) => {
                const checkinUtc = DateTime.fromISO(resultItem?.checkinUtc);
                return {
                    checkinUtc: checkinUtc,
                    supply: resultItem.supply,
                    demand: resultItem.demand
                }
            })
            .sort(compareLuxonDates);


        setChartSupplyData({
            datasets: [
                {
                    fill: false,
                    type: 'line' as const,
                    label: 'Supply',
                    data: transformedSupplyDemand,
                    parsing: {
                        yAxisKey: 'supply',
                        xAxisKey: 'checkinUtc',
                    },
                    borderColor: '#67a7e7',
                    backgroundColor: '#1976d2',

                }
            ],
        })



    }, [readMonitorRunResultGroupedByMonitorIdData]);
    //


    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdData === undefined) {
            return;
        }

        if (monitorLaunchTask === undefined) {
            return;
        }

        const transformedSupplyDemand = readMonitorRunResultGroupedByMonitorIdData.list
            .map((resultItem: any, index: number, list: any) => {
                const checkinUtc = DateTime.fromISO(resultItem?.checkinUtc);

                return {
                    checkinUtc: checkinUtc,
                    demand: resultItem.demand
                }
            })
            .sort(compareLuxonDates);

        const scales = {
            ...chartDemandOptions.scales
        }

        let maxValue = Math.max.apply(null, transformedSupplyDemand.map((o: any) => { return Math.abs(o.demand); }));
        maxValue++;
        scales.y.min = -maxValue;
        scales.y.max = maxValue;

        setChartDemandOptions({
            ...chartDemandOptions,
            scales
        });

        setChartDemandData({
            datasets: [
                {
                    fill: false,
                    type: 'line' as const,
                    label: 'Demand',
                    data: transformedSupplyDemand,
                    parsing: {
                        yAxisKey: 'demand',
                        xAxisKey: 'checkinUtc',
                    },
                    borderColor: '#61d800',
                    backgroundColor: '#008b00',

                }
            ],
        })

    }, [readMonitorRunResultGroupedByMonitorIdData]);

    useEffect(() => {
        if (readHostServicesByMonitorRunIdData === undefined) {
            return;
        }

        if (monitorLaunchTask === undefined) {
            return;
        }

        if (monitorLaunchTask.monitor === undefined) {
            return;
        }

        const groupedByPriceTotal = groupBy(readHostServicesByMonitorRunIdData.list, 'priceTotal');
        const arrGroupedByPriceTotal: any = Object.entries(groupedByPriceTotal)
            .map((entry: any, index: any) => {
                return {
                    'y': parseInt(entry[0]),
                    'x': entry[1].length,
                    'r': 3 + entry[1].length * 1.3,
                    'l': entry[1].length
                }
            });
        
        setChartData({
            datasets: [
                {
                    type: 'line' as const,
                    label: `Your Property Rate ${ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(monitorLaunchTask.monitor.priceForComparison) }`,
                    borderColor: 'rgba(46, 125, 50, 1)',
                    backgroundColor: 'rgba(46, 125, 50, 1)',
                },
                {
                    fill: false,
                    type: 'line' as const,
                    label: 'Competitors total prices',
                    data: arrGroupedByPriceTotal,
                    borderColor: '#67a7e7',
                    backgroundColor: '#1976d2',
                },
            ],
        });

        setChartOptions({
            plugins: {
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: monitorLaunchTask.monitor.priceForComparison,
                            yMax: monitorLaunchTask.monitor.priceForComparison,
                            borderColor: 'rgba(46, 125, 50, 1)',
                            borderWidth: 3,                          
                        },

                    }
                },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label: any = [];
      
                        if (context.parsed.y !== null) {
                            label.push(`Total price: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, }).format(parseInt(context.parsed.y))}`)

                            if (context.datasetIndex === 1) {
                                label.push(`Competitors count: ${context.raw.l}`)
                            }
                        }

                        return label;
                    }
                }
            }, 
        },
        scales: {
            x: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    precision: 0,
                    font: {
                        family: 'Times',
                
                        style: 'normal',
                        lineHeight: 1.2,
                    },
                },
                title: {
                    display: true,
                    text: 'Competitors count',
                    color: '#1976d2',
                    font: {
                        family: 'Times',                   
                        style: 'normal',
                        lineHeight: 1.2,
                        
                    },
                },
               beginAtZero: true,
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    callback: (value: any, index: any, ticks: any) => {
                        return '$' + value;
                    },
          
                    precision: 0,
                    font: {
                        family: 'Times',
                  
                        style: 'normal',
                        lineHeight: 1.2,
                    },
                },
                title: {
                    display: true,
                    text: 'Total price',
                    color: '#1976d2',
                    font: {
                        family: 'Times',
                 
                        style: 'normal',
                        lineHeight: 1.2,

                    },
                },
            }
        },
    })

    }, [monitorLaunchTask, readHostServicesByMonitorRunIdData]);

    const checkinUtc = DateTime.fromISO(monitorLaunchTask?.monitorRunsResult?.checkinUtc).toFormat('M/dd/yyyy');
    const checkOutUtc = DateTime.fromISO(monitorLaunchTask?.monitorRunsResult?.checkOutUtc).toFormat('M/dd/yyyy');  

    const renderAccordionSummaryHeading = () => {
        if (monitorLaunchTask !== undefined && monitorLaunchTask.monitorRunsResult?.checkinUtc !== undefined) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography sx={{
                        fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '24px',
                        },
                        lineHeight: '28px',
                        color: '#FFF',
                        marginRight: 2
                    }}
                    >Summary
                    </Typography>
                    <Box sx={{
                        fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '24px',
                        },
                        lineHeight: '28px',
                        color: '#FFF',
                        marginRight: 2,
                        display: 'flex', 
                        gap: '6px'
                    }}
                        onClick={ handleTitleCLick }
                    >
                        <Box>{`${checkinUtc}`}</Box>
                        <CalendarMonthIcon></CalendarMonthIcon>
                    </Box>
                </Box>
            )
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={{
                    fontSize: {
                        xs: '16px',
                        sm: '18px',
                        md: '24px',
                    },
                    lineHeight: '28px',
                    color: '#c62828',
                    marginRight: 2
                }}
                >Summary
                </Typography>
                <Typography sx={{
                    fontSize: {
                        xs: '16px',
                        sm: '18px',
                        md: '24px',
                    },
                    lineHeight: '28px',
                    color: '#c62828',
                    marginRight: 2
                }}
                >{`Select period above`}
                </Typography>
            </Box>
        )
    }

    const [readMonitorByIdQueryParams, setReadMonitorByIdQueryParams] = useState<any>(undefined);
    const [readMonitorByIdOptions, setReadMonitorByIdOptions] = useState<any>(
        {
            skip: true
        }
    );


    const {
        data: readMonitorByIdQueryData,
        isLoading: readMonitorByIdQueryIsLoading
    } = useReadMonitorByIdQuery(readMonitorByIdQueryParams, readMonitorByIdOptions);

    useEffect(() => {
        setReadMonitorByIdQueryParams({
            ...readMonitorByIdQueryParams,
            monitorId: monitorId
        });

        setReadMonitorByIdOptions(
            {
                ...readMonitorByIdOptions,
                skip: monitorId === undefined,
            }
        );
    }, [monitorId]);




    const handleTitleCLick = (params: any) => {
        if (typeof onTitleClick === "function") {
            onTitleClick(params);
        }
    }

    const handleYourPriceUpdated = async (event: any) => {

        if (monitorId === undefined) {
            return;
        }

        const price = parseFloat(event.target.value);
        if (isNaN(price)) {
            return;
        }

        setPrice(price);

        await updateMonitorOwnPrice({
            monitorId: monitorId,
            date: monitorLaunchTask?.monitorRunsResult?.checkinUtc,
            price: price
        })
    }

    const [price, setPrice] = useState<number>(0);
    useEffect(() => {

        if (monitorLaunchTask?.monitorRunsResult?.ownPrice === undefined) {
            if (readMonitorByIdQueryData === undefined) {
                setPrice(0);
                return;
            }

            setPrice(readMonitorByIdQueryData.priceForComparison);
            return;
        }

        if (monitorLaunchTask?.monitorRunsResult?.ownPrice !== undefined && monitorLaunchTask?.monitorRunsResult?.ownPrice === null) {
            if (readMonitorByIdQueryData === undefined) {
                setPrice(0);
                return;
            }

            setPrice(readMonitorByIdQueryData.priceForComparison);
            return;
        }

        setPrice(monitorLaunchTask?.monitorRunsResult?.ownPrice);
    }, [monitorLaunchTask, readMonitorByIdQueryData]);

    const getNumberValue = (value: any) => {
        if (value === undefined || value === null) {
            return 'unknown';
        }

        return `${value}`; 
    }

    const getMoneyValue = (value: any) => {
        if (value === undefined || value === null) {
            return 'unknown';
        }

        return `$${value}`; 
    }

    const getDateValue = (value: any) => {
        if (value === undefined || value === null) {
            return 'unknown';
        }

        return `${DateTime.fromISO(monitorLaunchTask?.monitorRunsResult?.timestampUtc).toFormat('M/dd/yyyy hh:mm  a') }`;
    }

    return (
        <>
            <Accordion ref={ref} disabled={monitorLaunchTask === undefined} expanded={monitorLaunchTask !== undefined} disableGutters defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#FFF' }} />}
                    aria-controls="panel1a-content"
                    sx={{
                        backgroundColor: '#B0B0B0',
                        paddingRight: { xs: '12px', md: '24px' },
                        paddingLeft: { xs: '12px', md: '24px' },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            padding: '12px 12px 12px 12px',
                        },
                        '& .MuiAccordionSummary-content': {
                            flexDirection: {
                                xs: 'column',
                                sm: 'row'
                            }
                        }
                    }}
                >
                    {renderAccordionSummaryHeading() }
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: '#EEE', p: 2 }}>
                    <Grid container spacing={2} sx={{ my: '10px', width: 'auto' }}>
                  
                    <Grid item xs={12} md={6}>
                            <Item key="com" sx={{ display: 'flex', minHeight: '72px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <CompetitorsIcon sx={{
                                    fontSize: '32px',

                                    
                                }} />
                                <Box component="span" sx={{ fontSize: '24px' }}>Competitors</Box>
                            </Box>
                            <Box component="span" sx={{
                                fontSize: '24px',

                                }}>
                                    {
                                        getNumberValue(monitorLaunchTask?.monitorRunsResult?.competitors)              
                                    }
                                </Box>
                        </Item>
                        </Grid>
                       

                        <Grid key="averagePrice" item xs={12} md={6}>
                            <Item sx={{ display: 'flex', minHeight: '72px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <AveragePriceIcon sx={{
                                        fontSize: '32px',
                                        fill: '#B0B0B0'
                                    }} />
                                    <Box component="span" sx={{ fontSize: '24px' }}>Median price</Box>
                                </Box>
                                <Box component="span" sx={{ fontSize: '24px' }}>
                                    {
                                        getMoneyValue(monitorLaunchTask?.monitorRunsResult?.medianPrice)
                                    }
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Item key="minPrice" sx={{ display: 'flex', minHeight: '72px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <MinPriceIcon sx={{
                                        fontSize: '32px',
                                        fill: '#B0B0B0'
                                    }} />
                                    <Box component="span" sx={{ fontSize: '24px' }}>Min price</Box>
                                </Box>
                                <Box component="span" sx={{ fontSize: '24px' }}>
                                    {
                                        getMoneyValue(monitorLaunchTask?.monitorRunsResult?.minPrice)
                                    }
                                </Box>
                            </Item>
                        </Grid>
                    <Grid item xs={12} md={6}>
                            <Item key="maxPrice" sx={{ display: 'flex', minHeight: '72px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <MaxPriceIcon sx={{
                                    fontSize: '32px',
                                    fill: '#B0B0B0'
                                }} />
                                <Box component="span" sx={{fontSize: '24px'}}>Max price</Box>
                            </Box>
                                <Box component="span" sx={{ fontSize: '24px' }}>
                                    {
                                        getMoneyValue(monitorLaunchTask?.monitorRunsResult?.maxPrice)                              
                                    }
                                </Box>
                        </Item>
                        </Grid>
     
       


                        <Grid key="supply" item xs={12} md={6}>
                            <Item sx={{ display: 'flex', minHeight: '72px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <FoundationIcon sx={{
                                        fontSize: '32px',
                                        fill: '#B0B0B0'
                                    }} />
                                    <Box component="span" sx={{ fontSize: '24px' }}>Supply</Box>
                                </Box>
                                <Box component="span" sx={{ fontSize: '24px' }}>
                                    {
                                        getNumberValue(monitorLaunchTask?.monitorRunsResult?.supply)                    
                                    }
                                </Box>
                            </Item>
                        </Grid>


                        <Grid key="demand" item xs={12} md={6}>
                            <Item sx={{ display: 'flex', minHeight: '72px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <FoundationIcon sx={{
                                        fontSize: '32px',
                                        fill: '#B0B0B0'
                                    }} />
                                    <Box component="span" sx={{ fontSize: '24px' }}>Demand</Box>
                                </Box>
                                <Box component="span" sx={{ fontSize: '24px' }}>
                                    {
                                        getNumberValue(monitorLaunchTask?.monitorRunsResult?.demand)
                                    }
                                </Box>
                            </Item>
                        </Grid>
                        <Grid key="updated" item xs={12} md={6}>
                            <Item sx={{ display: 'flex', minHeight: '72px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <UpdateIcon sx={{
                                        fontSize: '32px',
                                        fill: '#B0B0B0'
                                    }} />
                                    <Box component="span" sx={{ fontSize: '24px' }}>Updated</Box>
                                </Box>
                                <Box component="span" sx={{ fontSize: '24px' }}>
                                    {
                                        getDateValue(monitorLaunchTask?.monitorRunsResult?.timestampUtc)
                                    }
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>

                    <Box sx={{
                        display: 'grid',
                        gap: {
                            xs: '20px',
                            md: '30px'
                        },
                        gridTemplateColumns: {
                            xs: '100%',
                            md: '1fr 1fr'
                        }
                    }}>
                        <ChartSupplyDemand
                            data={chartSupplyData}
                            options={chartSupplyOptions}
                            loading={chartSupplyData === undefined || monitorLaunchTask === undefined}
                        />             
                        <ChartSupplyDemand
                            data={chartDemandData}
                            options={chartDemandOptions}
                            loading={chartDemandData === undefined || monitorLaunchTask === undefined}
                        />  
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
})