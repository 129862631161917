import Box from '@mui/material/Box';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { GridApi } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid';

type RecomendationsProps = {
    recomendations?: any, 
    loading?: boolean,
    onItemClick?: Function,
    selectedRowId?: number
}

const columns: GridColDef[] = [
    {
        field: 'date',
        headerName: 'Date',
        width: 110,

        valueGetter: (params: GridValueGetterParams) => {
            return DateTime.fromISO(params?.row?.date).toFormat('M/dd/yyyy');
        }
    },
    {
        field: 'recommendation',
        headerName: 'Recommendation',
        width: 190,
        valueGetter: (params: GridValueGetterParams) => {
            return params?.row?.recommendation?.text;
        },
        cellClassName: (params: GridCellParams<any>) => {
     
            if (params?.row?.recommendation.priceDirection === 0) {
                return 'MuiDataGrid-cell--status-ok';
            }

            if (params?.row?.recommendation?.text !== undefined) {
                return 'MuiDataGrid-cell--highlighted';
            }

            return '';        
        }
    },
    {
        field: 'medianPrice',
        headerName: 'Median price',
        width: 140,
        valueGetter: (params: GridValueGetterParams) => {
            return `$${Math.round(params.row.monitorLaunchTask.medianPrice)}`
        }
    },

]

export const Recomendations = (props: RecomendationsProps): JSX.Element => {
    const {
        recomendations,
        loading,
        onItemClick,
        selectedRowId
    } = props;

    const apiRef = useGridApiRef();

    const handleRowClick = (event: any) => {
        if (typeof onItemClick === "function") {
            onItemClick(event.row);
        }
    }

    useEffect(() => {
        if (apiRef === undefined) {
            return;
        }

        if (apiRef.current === undefined) {
            return;
        }

        if (selectedRowId === undefined) {
            return;
        }

        apiRef.current.scrollToIndexes({
            rowIndex: apiRef.current.getRowIndexRelativeToVisibleRows(selectedRowId),
            colIndex: 0,
        });


    }, [selectedRowId])

    const handleGetRowClassName = (params: any): string => {
        if (params.id === selectedRowId) {
            return `MuiDataGrid-row--selected`;
        }

        return ``;
    }

    return (
        <>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                <DataGrid
                    apiRef={apiRef}
                    onRowClick={handleRowClick}
                    sx={{
                        height: loading ? '180px' : 'auto',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            },
                            fontWeight: '600'
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            }
                        },

                        "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important"
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "168px !important"
                        },
                        minHeight: 180,
                        maxHeight: 400,
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer'
                        },
                        '& .MuiDataGrid-row--selected': {
                            backgroundColor: `#aecdeb`

                        },
                        '& .MuiDataGrid-cell--highlighted': {
                            color: `#ef5350`
                        },
                        '& .MuiDataGrid-cell--status-ok': {
                            color: `#4caf50`
                        },
                    }}
                    getRowClassName={handleGetRowClassName}
                    rows={recomendations || []}
                    columns={columns}
                    disableRowSelectionOnClick
                />
            </Box>
        </>
    )
}